import React, {useEffect, useState} from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import SearchCustom from 'app/components/Search/SearchCustom'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'
import { useGetNewMember } from 'app/hooks/useUser'
import { Helmet } from 'react-helmet';
import {count} from "rxjs";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
  responsive: true,
  maxBarThickness: 70,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: '',
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  },
}

const DataByPeriod = () => {
  const { t } = useTranslation('')
  const [payload, setPayload] = useState({})
  const { data: list, isLoading, isError, isSuccess } = useGetNewMember(payload)
  const [countNewMember, setCountNewMember ] = useState(0)
  
  useEffect(() => {
    if (list) {
      console.log('data', list.data.data)
      setCountNewMember(list.data.data)
    }
  }, [list])
  const handleSearch = (data:any) => {
    setPayload({start_time: data.startTime, end_time: data.endTime})
  }

  return (
    <div>
      <Helmet>
        <title>期間ごとの指標</title>
      </Helmet>
      <header>
        <h4>{t('indexManager.title')}</h4>
        <SearchCustom handleSearch={handleSearch}/>
      </header>
      <main className={styles.wrapCharContent}>
        <Bar
          options={options}
          data={{
            labels: [''],
            datasets: [
              {
                label: t('indexManager.dataByPeriod.chartTitle1'),
                data: [countNewMember, 100],
                backgroundColor: 'green',
              },
            ],
          }}
        />
      </main>
    </div>
  )
}

export default DataByPeriod
