import React from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

function TranslateWithSpecifictFile() {
  const { t } = useTranslation('messages')

  return (
    <>
      <h3>Message from ja/messages: {t('hello')}</h3>
      <h3>Homepage from ja/messages: {t('homepage.title')}</h3>
    </>
  )
}

function TranslateWithDefault() {
  const { t } = useTranslation('')

  return (
    <>
      <h3>Message: {t('hello')}</h3>

      <h3>Homepage:  {t('homepage.title')}</h3>

      <h3>Title from ja/messages: {t('messages:homepage.title')}</h3>
    </>
  )
}

export default function I18nExample() {
  function handleClick(lang: string) {
    i18next.changeLanguage(lang)
  }

  return (
    <div className="i18n" style={{ fontSize: '12px' }}>
      <nav style={{ width: '100%', padding: '2rem 0', backgroundColor: 'gray' }}>
        <h3>Default translate file is translation</h3>
        <button onClick={() => handleClick('en')}>English</button>
        <button onClick={() => handleClick('ja')}>Japanse</button>
        <button onClick={() => handleClick('ko')}>korea</button>
      </nav>

      <br />
      <br />
      <TranslateWithSpecifictFile />
      <br />
      <br />
      <TranslateWithDefault />
    </div>
  )
}
