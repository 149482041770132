import { useQuery } from 'react-query'
import {getLostDog, getNotification} from "app/axios/dogApi";

export const useGetLostDog = (params:{}) => {
  return useQuery(['lost_dog', params],
    () => getLostDog(params)
  ) as any
}

export const useGetNotificationDetail = (params: any) => {
  return useQuery(['lost_dog', params],
    () => getNotification(params)
  ) as any
}