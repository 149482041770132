import React, { useEffect } from 'react'
import { Button, Form, Image, Input } from 'antd'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { useResetPassword } from 'app/hooks/useAuth'
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
const ResetPassword: React.FC = () => {
  const { t } = useTranslation('')
  const navigate = useNavigate()
  const token = window.location.search.split('=')[1]
  const { mutate, data , isLoading, isSuccess, isError } = useResetPassword()
  const onFinish = (values: any) => {
    mutate({...values, token: token})
  }

  useEffect(()=> {
    if(isSuccess){
      toast('成功しました。')
      navigate('/login')
    }
  },[isSuccess])

  useEffect(()=> {
    if(isError){
      toast('reset password fail')
    }
  },[isError])

  return (
    <div className="wrapCenter">
      <Helmet>
        <title>함께걷개</title>
      </Helmet>
      <div className={styles.wrapLogin}>
        <header>
          <Image
            src={require('../../../assets/images/logo.png')}
            width={300}
            className={styles.logo}
            preview={false}
          />
        </header>
        <Form
          className={styles.loginForm}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
        >
         <Form.Item
          name="password"
          label="パスワード"
          hasFeedback
          rules={[
            {
              required: true,
              message: '入力してください。',
            },
            ({getFieldValue}) => ({
              validator(_, value) {
                 if (value && (value.length < 8 || value.length > 16)) {
                  return Promise.reject(new Error('パスワードは8~16（文字と数字）、半角を入力してください。'));
                } else {
                   return Promise.resolve();
                 }
              },
            }),
          ]}
        >
          <Input.Password placeholder={t('resetPassword.placeholder.password')}/>
        </Form.Item>

        <Form.Item
          name="password_confirm"
          label="新しいパスワード（確認用）"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: '入力してください。',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('パスワードは一致していません'));
              },
            }),
          ]}
        >
          <Input.Password placeholder={t('resetPassword.placeholder.confirmPassword')}/>
        </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className={styles.loginBtn}>
              {t('resetPassword.button.title')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default ResetPassword
