import React, { useEffect } from 'react'
import { Button, Form, Image, Input } from 'antd'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { useLogin } from 'app/hooks/useAuth'
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
const Login: React.FC = () => {
  const { t } = useTranslation('')
  const { mutate: login, data , isLoading, isError, isSuccess } = useLogin()

  const onFinish = (values: any) => {
    console.log('Received values of form: ', values)
    login(values)
  }

  useEffect(()=> {
    if(isSuccess){
      toast('Login success')
    }
  },[isSuccess])

  useEffect(()=> {
    if(isError){
      toast('メールアドレスまたはパスワードが正しくありません。再度入力してください。')
    }
  },[isError])

  return (
    <div>
      <Helmet>
      <title>함께걷개</title>
    </Helmet>
    <div className="wrapCenter">
      <div className={styles.wrapLogin}>
        <header>
          <Image
            src={require('../../../assets/images/logo.png')}
            width={300}
            className={styles.logo}
            preview={false}
          />
        </header>
        <main className={styles.loginForm}>
          <Form
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
            requiredMark={true}
            className="custom-form"
          >
            <Form.Item
              label="メールアドレス"
              name="email"
              className={'labelEmail'}
              rules={[
                ({getFieldValue}) => ({
                  validator(_, value) {
                    var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                    if (!value || value === '') {
                      return Promise.reject(new Error('入力してください。'));
                    } else if (value && value.length > 255) {
                      return Promise.reject(new Error('255文字以内で入力してください。'));
                    } else if (!pattern.test(value)) {
                      return Promise.reject(new Error('メールアドレスを正しく入力してください。'));
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input placeholder={t('login.placeholder.username')} />
            </Form.Item>
            <Form.Item
              label="パスワード"
              name="password"
              rules={[
                {
                  required: true,
                  message: '入力してください。',
                },
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (value && (value.length < 8 || value.length > 16)) {
                      return Promise.reject(new Error('パスワードは8~16（文字と数字）、半角を入力してください。'));
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder={t('login.placeholder.password')}
              />
            </Form.Item>
            <Form.Item className={styles.remember}>
              <a href="/forgot-password">{t('login.label.forgotPassword')}</a>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className={styles.loginBtn}>
                {t('login.button.title')}
              </Button>
            </Form.Item>
          </Form>
        </main>
      </div>
      <ToastContainer/>
    </div>
    </div>
  )
}

export default Login
