import { forgotPassword, login, resetPassword } from 'app/axios/authApi'
import { STORAGE } from 'app/constants/common'
import { dataForgotPasswordType, dataLoginType } from 'app/types/interfaces/Props/auth'
import { save } from 'react-cookies'
import { useMutation } from 'react-query'

export const useLogin = () => {
  return useMutation((params: dataLoginType) => login(params), {
    onSuccess: (res) => {
      save(STORAGE.ACCESS_TOKEN, res.data.data.access_token, { domain: '' })
      save(
        STORAGE.USER_INFO,
        { nickname: res.data.data.nickname, avatar: res.data.data.avatar },
        { domain: '' },
      )
      window.location.href = 'user'
    },
    onError: (error) => {
      console.log(error)
    },
  })
}

export const useForgotPassword = () => {
  return useMutation((params: dataForgotPasswordType) => forgotPassword(params), {
    onSuccess: (res) => {
      console.log(123, res)
    },
    onError: (error) => {
      console.log(error)
    },
  })
}

export const useResetPassword = () => {
  return useMutation((params: dataForgotPasswordType) => resetPassword(params), {
    onSuccess: (res) => {},
    onError: (error) => {
      console.log(error)
    },
  })
}
