import React, {useEffect, useState} from 'react'
import styles from './style.module.scss'
import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { Link } from 'react-router-dom'
import { EyeOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import SearchCustom from 'app/components/Search/SearchCustom'
import {useGetUserList} from "app/hooks/useUser";
import {useGetConnectionLog} from "app/hooks/useConnectionLog";
import moment from 'moment'
import CustomPagination from "app/components/Pagination";
import { Helmet } from 'react-helmet';

interface DataType {
  key: React.Key
  email: string
  userName: string
  lastLogin: string
  ipLogin: string
}

interface dataSearch {
  startTime: string,
  endTime: string,
  nickname: string
}

const UserStatistic = () => {
  const { t } = useTranslation('')
  const [listConnectionList, setListConnectionList] = useState([])
  const [payload, setPayload] = useState({
    nickname: undefined,
    page: 1
  })
  const [currentPage, setCurrentPage] = useState(1)
  const { data: list, isLoading, isError, isSuccess } = useGetConnectionLog(payload)
  const [pageSize, setPageSize] = useState(10)
  const [totalItems, setTotalItems] = useState(0)
  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    // Call API chuyển trang
    setPayload({...payload, page: page})
  }
  
  const handleSearch = (value:any) => {
    setPayload(value)
  }
  
  useEffect(() => {
    if (list) {
      setListConnectionList(list.data.data)
      setPageSize(list.data.meta.take)
      setTotalItems(list.data.meta.item_count)
    }
  }, [list])
  const columns: ColumnsType<DataType> = [
    {
      title: t('userManager.label.email'),
      dataIndex: 'member',
      key: '1',
      width: 150,
      render: (value) => {
        return (
          <span>{value.email}</span>
        )
      }
    },
    {
      title: t('userManager.label.userName'),
      dataIndex: 'member',
      key: '2',
      width: 150,
      render: (value) => {
        return (
          <span>{value.nickname}</span>
        )
      }
    },
    {
      title: t('userManager.label.lastLogin'),
      dataIndex: 'login_time',
      key: '3',
      width: 150,
      render: (value) => {
        return (
          <span>{moment(value).format("YYYY-MM-DD HH:mm:ss")}</span>
        )
      }
    },
    {
      title: t('userManager.label.ipLogin'),
      dataIndex: 'login_ip',
      key: '4',
      width: 150,
    },
  ]
  
  let locale = {
    emptyText: 'データがありません。',
  };
  
  return (
    <div className={styles.wrapContainer}>
      <Helmet>
        <title>接続ログ</title>
      </Helmet>
      <header>
        <h4>{t('userManager.userStatistic.title')}</h4>
        <SearchCustom
          isSearchText
          handleSearch={handleSearch}
        />
      </header>
      <main>
        <Table
          locale={locale}
          columns={columns}
          dataSource={listConnectionList}
          pagination={false}
        />
        <CustomPagination
          totalItems={totalItems}
          currentPage={currentPage}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </main>
    </div>
  )
}

export default UserStatistic
