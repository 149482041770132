import React, {useEffect, useState} from 'react'
import styles from './style.module.scss'
import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { Link } from 'react-router-dom'
import { EyeOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import SearchCustom from 'app/components/Search/SearchCustom'
import {useGetUserList} from "app/hooks/useUser";
import {useGetLostDog} from "app/hooks/userDog";
import CustomPagination from "app/components/Pagination";
import moment from "moment";
import { Helmet } from 'react-helmet';
interface DataType {
  key: React.Key
  id: React.Key
  namePet: string
  bithDayPet: string
  petBreed: string
  petGender: string
  petCode: string
}

interface dataSearch {
  startTime: string,
  endTime: string,
  dog_name: string
}

const NotifyManager = () => {
  const { t } = useTranslation('')
  
  const [listNotify, setListNotify] = useState([])
  const [payload, setPayload] = useState({
    nickname: undefined,
    page: 1
  })
  const [currentPage, setCurrentPage] = useState(1)
  const { data: list, isLoading, isError, isSuccess } = useGetLostDog(payload)
  const [pageSize, setPageSize] = useState(10)
  const [totalItems, setTotalItems] = useState(0)
  
  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    // Call API chuyển trang
    setPayload({...payload, page: page})
  }
  
  const handleSearch = (value:any) => {
    setPayload(value)
  }
  
  useEffect(() => {
    if (list) {
      setListNotify(list.data.data)
      setPageSize(list.data.meta.take)
      setTotalItems(list.data.meta.item_count)
    }
  }, [list])
  const columns: ColumnsType<DataType> = [
    {
      title: t('notifyManager.label.namePet'),
      dataIndex: 'dog',
      key: '1',
      width: 150,
      render: (value) => {
        return (
          <span>{value.dog_name}</span>
        )
      }
    },
    {
      title: t('notifyManager.label.bithDayPet'),
      dataIndex: 'dog',
      key: '2',
      width: 150,
      align: 'center',
      render: (value) => {
        return (
          <span>{moment(value.birth_dt).format("YYYY-MM-DD")}</span>
        )
      }
    },
    {
      title: t('notifyManager.label.petBreed'),
      dataIndex: 'dog',
      key: '3',
      width: 150,
      align: 'center',
      render: (value) => {
        return (
          <span>{value.dog_breed.dog_breed_name_ja}</span>
        )
      }
    },
    {
      title: t('notifyManager.label.petGender'),
      dataIndex: 'dog',
      key: '4',
      align: 'center',
      width: 150,
      render: (value) => {
        return (
          <span>{value.dog_gender == "M" ? 'オス' : 'メス'}</span>
        )
      }
    },
    {
      title: t('notifyManager.label.petCode'),
      dataIndex: 'dog',
      key: '5',
      width: 150,
      render: (value) => {
        return (
          <span>{value.dog_number}</span>
        )
      }
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      align: 'center',
      width: 50,
      render: (value) => <Link to={`/notify/${value.id}`}>{<EyeOutlined />}</Link>,
    },
  ]
  
  let locale = {
    emptyText: 'データがありません。',
  };
  return (
    <div className={styles.wrapContainer}>
      <Helmet>
        <title>迷子犬の通報</title>
      </Helmet>
      <header>
        <h4>{t('notifyManager.title')}</h4>
        <SearchCustom
          isSearchText
          handleSearch={handleSearch}
        />
      </header>
      <main>
        <Table
          locale={locale}
          columns={columns}
          dataSource={listNotify}
          pagination={false}
        />
        <CustomPagination
          totalItems={totalItems}
          currentPage={currentPage}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </main>
    </div>
  )
}

export default NotifyManager
