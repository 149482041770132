import React, { useState, useEffect } from 'react'
import styles from './style.module.scss'
import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useGetUserDetail } from 'app/hooks/useUser'
import { BackwardOutlined } from '@ant-design/icons'
import moment from 'moment'
import {useNavigate} from 'react-router-dom';
import { Helmet } from 'react-helmet';

interface DataType {
  key: React.Key
  namePet: string
  birthDayPet: string
  codePet: string
}

const UserDetail = () => {
  const moment = require('moment-timezone');
  const navigate = useNavigate();
  const navigateToUser = () => {
    navigate('/user');
  };
  const { t } = useTranslation('')
  const { id } = useParams()
  const [user, setUser] = useState<{ [x: string]: any }>({})
  const { data: userDetail, isSuccess } = useGetUserDetail(id)

  useEffect(() => {
    if (isSuccess) {
      setUser(userDetail.data.data)
    }
  }, [isSuccess])

  const columns: ColumnsType<DataType> = [
    {
      title: t('userManager.userDetail.label.namePet'),
      dataIndex: 'dog_name',
      key: '1',
      width: 150,
    },
    {
      title: t('userManager.userDetail.label.birthDayPet'),
      dataIndex: 'birth_dt',
      key: '2',
      width: 150,
      align: 'center',
      render: (value) => {
        return <span>{moment(value).format('YYYY-MM-DD')}</span>
      },
    },
    {
      title: t('userManager.userDetail.label.codePet'),
      dataIndex: 'dog_number',
      key: '3',
      align: 'center',
      width: 150,
    },
  ]
  let locale = {
    emptyText: 'データがありません。',
  };
  return (
    <div className={'wrapContainer'}>
      <Helmet>
        <title>ユーザー情報</title>
      </Helmet>
      <header>
        <h4 className={styles.title}>{t('userManager.userDetail.title')}</h4>
      </header>
      <main>
        <div className={'wrapContent'} style={{ overflowY: "scroll", scrollbarWidth: "none" }}>
          <div className={styles.contentDetail}>
            <ul className={styles.boxLeft}>
              <li className={styles.boxDogItem}>
                <span className={styles.itemLeft}>{t('userManager.label.email')}: </span>
                <span className={styles.itemRight}  style={{ paddingLeft: 20 }}>{user.email}</span>
              </li>
              <li className={styles.boxDogItem}>
                <span className={styles.itemLeft}>{t('userManager.label.userName')}: </span>
                <span className={styles.itemRight}  style={{ paddingLeft: 20 }}>{user.nickname}</span>
              </li>
              <li className={styles.boxDogItem}>
                <span className={styles.itemLeft}>{t('userManager.label.createAt')}:</span>
                <span className={styles.itemRight}  style={{ paddingLeft: 20 }}>
                {moment(user.created_at).format('YYYY-MM-DD')}
              </span>
              </li>
              <li className={styles.boxDogItem}>
                <span className={styles.itemLeft}>{t('userManager.label.lastLogin')}:</span>
                <span className={styles.itemRight}  style={{ paddingLeft: 20 }}>
                {moment(user.last_login).tz('Asia/Tokyo').format('YYYY-MM-DD HH:MM:SS')}
              </span>
              </li>
              <li className={styles.boxDogItem}>
                <span className={styles.itemLeft}>{t('userManager.label.ipLogin')}: </span>
                <span className={styles.itemRight}  style={{ paddingLeft: 20 }}>{user.ip}</span>
              </li>
            </ul>
          </div>
          <Table locale={locale} columns={columns} dataSource={user.dogs || []} pagination={false}/>
        </div>
      </main>
          <div className="footerDetail">
            <button className="btnBack"  onClick={navigateToUser}  >
              <BackwardOutlined className="iconBack" />
              <p className="textBack">戻る</p>
            </button>
          </div>
    </div>
  )
}

export default UserDetail
