import HttpClient from './core.service'
const { get, post, del, put } = HttpClient

export const getUser = (payload: any) => get('/admin/list-user', payload)
export const getUserDetail = (id:any) => get('/admin/user_detail/'+id)
export const getNewMember = (payload:any) => get(`/admin/stat/new_member`, payload)
// export const getListExample = (payload: any) => get('/api/v1/example', payload);
// export const deleteExample = (id: any) => del(`/api/v1/example/${id}`);
// export const createExample = (payload: any) => post('/api/v1/example', payload);
// export const getDetailExample = (id: any) => get(`/api/v1/example/${id}`);
// export const updateExample = (payload: any, id: any) =>
//   put(`/api/v1/example/${id}`, payload);
