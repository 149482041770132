import React, { useRef, useState, useTransition } from 'react'
import crudExampleService from 'app/services/crud-example/crud-example.service'
import { SubmitHandler, useForm } from 'react-hook-form'
import './style.css'
import { ICRUDFormInput } from 'app/types/interfaces/crud-example'
import TextError from 'app/components/TextError'
import { useTranslation } from 'react-i18next'

export default function CRUDComponent() {
  const { t } = useTranslation('validate')

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<ICRUDFormInput>()

  const [listItems, setList] = useState<ICRUDFormInput[]>([])
  const flagState = useRef<'edit' | 'no-edit'>('no-edit')

  const add = (payload: ICRUDFormInput) => {
    // set loading
    crudExampleService.addProduct(payload).then((res) => {
      // end loading
      if (res) {
        flagState.current = 'no-edit'
        // use value from response instead hardcode (payload)
        setList([...listItems, { ...payload, id: Math.random() }])
        reset()
      }
    })
  }

  const update = (payload: ICRUDFormInput) => {
    // set loading
    crudExampleService.updateProduct(payload).then((res) => {
      // end loading
      setList((pre) => pre.map((ele) => (ele.id === payload.id ? payload : ele)))
      flagState.current = 'no-edit'
      reset()
    })
  }

  const deleteProduct = (id: number) => {
    // set loading
    crudExampleService.deleteProduct({ id }).then((res) => {
      // end loading
      // handle case by case
      if (res) {
        const filtered = listItems.filter((ele) => ele.id !== id)
        setList(filtered)
      }
    })
  }

  const editProduct = (id: number) => {
    flagState.current = 'edit'
    const item = listItems.find((item) => item.id === id)
    if (item) {
      Object.entries(item).forEach(([name, value]) => setValue(name as keyof ICRUDFormInput, value))
    } else {
      //
    }
  }

  const onSubmit: SubmitHandler<ICRUDFormInput> = (data) => {
    if (flagState.current === 'edit') {
      update(data)
    } else {
      add(data)
    }
  }

  return (
    <>
      <h1>CRUD component</h1>

      <div className="list-item">
        {Array.isArray(listItems)
          ? listItems.map((item) => {
              return (
                <div className="item" key={item.id}>
                  <span>Name: {item.name}</span>
                  <button onClick={() => editProduct(item.id)}>Edit</button>
                  <button onClick={() => deleteProduct(item.id)}>Delete</button>
                </div>
              )
            })
          : ''}
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-control mb-2">
          <label htmlFor="code">Code</label>
          <input
            className="form-control"
            id="code"
            type="text"
            {...register('code', {
              required: {
                value: true,
                message: t('crudExample.required'),
              },
              maxLength: {
                value: 10,
                message: t('crudExample.maxlength10'),
              },
            })}
            onBlur={(e) => setValue('code', e.target.value.trim())}
          />
          {errors.code && <TextError label={errors.code.message} />}
        </div>

        <div className="form-control mb-2">
          <label htmlFor="name">Name</label>
          <input
            id="name"
            className="form-control"
            type="text"
            {...register('name', {
              required: {
                value: true,
                message: t('crudExample.required'),
              },
              maxLength: {
                value: 20,
                message: t('crudExample.maxlength20'),
              },
            })}
            onBlur={(e) => setValue('name', e.target.value.trim())}
          />
          {errors.name && <TextError label={errors.name.message} />}
        </div>

        <button className="btn btn-primary" type="submit">
          Submit
        </button>
      </form>
    </>
  )
}
