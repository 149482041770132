import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import SearchCustom from 'app/components/Search/SearchCustom'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
  responsive: true,
  maxBarThickness: 70,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: '',
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  },
}

const GeneralTrend = () => {
  const { t } = useTranslation('')
  return (
    <div>
      <header>
        <h4>{t('indexManager.generalTrend.title')}</h4>
        <SearchCustom />
      </header>
      <main className={styles.wrapCharContent}>
        <Bar
          options={options}
          data={{
            labels: [''],
            datasets: [
              {
                label: t('indexManager.generalTrend.chartTitle1'),
                data: [63],
                backgroundColor: '#437EC4',
              },
              {
                label: t('indexManager.generalTrend.chartTitle2'),
                data: [116],
                backgroundColor: '#C4A843',
              },
              {
                label: t('indexManager.generalTrend.chartTitle3'),
                data: [82],
                backgroundColor: '#C44B43',
              },
              {
                label: t('indexManager.generalTrend.chartTitle4'),
                data: [38],
                backgroundColor: '#808386',
              },
            ],
          }}
        />
      </main>
    </div>
  )
}

export default GeneralTrend
