import { getNewMember, getUser ,getUserDetail} from 'app/axios/userApi'
import { STORAGE } from 'app/constants/common'
import { dataForgotPasswordType, dataLoginType } from 'app/types/interfaces/Props/auth'
import { save } from 'react-cookies'
import { useMutation,useQuery } from 'react-query'

export const useGetUserList = (params:{}) => {
  return useQuery(['my_dog_list', params],
  () => getUser({...params})
) as any
}

export const useGetUserDetail = (params:any) => {
  return useQuery(['my_dog_list', params],
  () => getUserDetail(params)
) as any
}

export const useGetNewMember = (params:any) => {
  return useQuery(['new_member', params],
  () => getNewMember(params)
) as any
}

