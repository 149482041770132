import { App } from 'antd'
import ForgotPassword from 'app/pages/Auth/ForgotPassword'
import Login from 'app/pages/Auth/Login'
import ResetPassword from 'app/pages/Auth/ResetPassword'
import SignUp from 'app/pages/Auth/SignUp'
import DataByPeriod from 'app/pages/IndexManager'
import GeneralTrend from 'app/pages/IndexManager/GeneralTrend'
import NotifyManager from 'app/pages/NotifyManager'
import NotifyDetail from 'app/pages/NotifyManager/NotifyDetail'
import UserManager from 'app/pages/UserManager'
import UserDetail from 'app/pages/UserManager/UserDetail'
import UserStatistic from 'app/pages/UserManager/UserStatistic'
import CRUDComponent from 'app/pages/crud_example'
import I18nExample from 'app/pages/i18n'
import { RouteType } from 'app/types/interfaces/router/Common'

export const appRouter: RouteType[] = [
  {
    name: 'Home',
    path: '/',
    component: UserManager,
    icon: 'fa fa-diamond',
    meta: {
      role: '*',
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: 'Demo I18n',
    path: 'i18n',
    component: I18nExample,
    icon: 'fa fa-diamond',
    meta: {
      role: '*',
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: 'CRUD example',
    path: 'crud-example',
    component: CRUDComponent,
    icon: 'fa fa-diamond',
    meta: {
      role: '*',
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: 'Reset Password',
    path: 'reset-password',
    component: ResetPassword,
    icon: 'fa fa-diamond',
    meta: {
      role: '*',
      isPrivate: false,
      hidden: false,
      child: false,
    },
  },
  {
    name: 'login',
    path: 'login',
    component: Login,
    icon: 'fa fa-diamond',
    meta: {
      role: '*',
      isPrivate: false,
      hidden: false,
      child: false,
    },
  },
  {
    name: 'register',
    path: 'register',
    component: SignUp,
    icon: 'fa fa-diamond',
    meta: {
      role: '*',
      isPrivate: false,
      hidden: false,
      child: false,
    },
  },
  {
    name: 'Forgot Password',
    path: 'forgot-password',
    component: ForgotPassword,
    icon: 'fa fa-diamond',
    meta: {
      role: '*',
      isPrivate: false,
      hidden: false,
      child: false,
    },
  },
  {
    name: 'User Manager',
    path: 'user',
    component: UserManager,
    icon: 'fa fa-diamond',
    meta: {
      role: '*',
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: 'User Statistic',
    path: 'user/connection-log',
    component: UserStatistic,
    icon: 'fa fa-diamond',
    meta: {
      role: '*',
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: 'User Detail',
    path: 'user/:id',
    component: UserDetail,
    icon: 'fa fa-diamond',
    meta: {
      role: '*',
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: 'Data By Period',
    path: 'data-by-period',
    component: DataByPeriod,
    icon: 'fa fa-diamond',
    meta: {
      role: '*',
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: 'General Trend',
    path: 'general-trend',
    component: GeneralTrend,
    icon: 'fa fa-diamond',
    meta: {
      role: '*',
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: 'Notify Manager',
    path: 'notify',
    component: NotifyManager,
    icon: 'fa fa-diamond',
    meta: {
      role: '*',
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: 'Notify Detail',
    path: 'notify/:id',
    component: NotifyDetail,
    icon: 'fa fa-diamond',
    meta: {
      role: '*',
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
]
