import React, { useState } from 'react'
import {Button, Form, Input} from 'antd'
import dayjs from 'dayjs'
import { DatePicker, ConfigProvider } from 'antd'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import zhCN from 'antd/locale/ja_JP'
import 'dayjs/locale/ja'
import moment from 'moment'

const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD'
interface SearchCustomProps {
  isSearchText?: boolean
  handleSearch?: any
}

const SearchCustom = ({ isSearchText = false, handleSearch }: SearchCustomProps) => {
  const { t } = useTranslation('')
  const [nickname, setNickname] = useState<string | undefined>('')
  const [dates, setDates] = useState([])
  const onSearch = () => {
    const dataSearch = {
      startTime: dates && dates[0] ? moment(dates[0]).format('YYYY-MM-DD') : null,
      endTime: dates && dates[1] ? moment(dates[1]).format('YYYY-MM-DD') : null,
      q: nickname ? nickname : '',
    }
    handleSearch(dataSearch)
  }
  const handleChangeDate = (value: any, valueString:any) => {
    setDates(valueString)
  }
  return (
    <div className={styles.wrapSearch}>
        <Form className={'formSearch'}>
          { isSearchText && (
            <Form.Item
              name="searchText"
              rules={[
                {
                  max: 255,
                  message: '255文字以内で入力してください。',
                },
              ]}
            >
              <Input
                className={styles.searchInput}
                onChange={(e) => {
                  setNickname(e.currentTarget.value)
                }}
                placeholder="検索入力"
                onKeyUp={(ev) => {
                  if (ev.keyCode === 13) {
                    onSearch()
                  }
                }}
              />
            </Form.Item>
          )}
          <Form.Item>
            <ConfigProvider locale={zhCN}>
              <RangePicker
                allowEmpty={[true, true]}
                style={{ marginLeft: 10 }}
                placeholder={["開始日", "終了日"]}
                defaultValue={null}
                format={dateFormat}
                onChange={(value, valueString) => handleChangeDate(value, valueString)} />
            </ConfigProvider>
          </Form.Item>
          <Form.Item>
      <Button className={'btnSearch'} style={{ marginLeft: 10 }} type="primary" onClick={onSearch}>
        {t('button.search')}
      </Button>
          </Form.Item>
        </Form>
    </div>
  )
}

export default SearchCustom
