import HttpClient from "app/axios/core.service";
import { API_URL } from "app/constants";
import { StatusCode } from "app/types/enums";
import { IExampleAdd, IExampleDelete, IExampleUpdate } from "app/types/interfaces/crud-example";

class CrudExample {
    async addProduct(payload: IExampleAdd): Promise<unknown | null> {
        return new Promise((rs) => {
            const { name, code } = payload;
            HttpClient.post(API_URL.CRUD_EXAMPLE.ADD, { name, code }).then(res => {
                if (res && res.status === StatusCode.SUCCESS) {
                    // resolve something...
                    rs(true);
                } else {
                    // resolve something...
                    rs(true);
                }
            }).catch(error => {
                // resolve something...
                rs(true);
            })
        })
    }

    async deleteProduct(payload: IExampleDelete): Promise<unknown | null> {
        return new Promise((rs) => {
            const { id } = payload;
            HttpClient.del(API_URL.CRUD_EXAMPLE.DELETE, id).then(res => {
                if (res && res.status === StatusCode.SUCCESS) {
                    // resolve something...
                    rs(true);
                } else {
                    // resolve something...
                    rs(true);
                }
            }).catch(error => {
                rs(true);
            })
        })
    }

    async updateProduct(payload: IExampleUpdate): Promise<unknown | null> {
        return new Promise((rs) => {
            HttpClient.put(API_URL.CRUD_EXAMPLE.UPDATE, payload).then(res => {
                if (res && res.status === StatusCode.SUCCESS) {
                    // resolve something...
                    rs(true);
                } else {
                    // resolve something...
                    rs(true);
                }
            }).catch(error => {
                // resolve something...
                rs(true);
            })
        })
    }
}

export default new CrudExample;