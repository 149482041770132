import { AxiosInstance } from "axios";

const requestInterceptor = (axiosInstance: AxiosInstance) => {
    axiosInstance.interceptors.request.use((config) => {
        if (config.headers) {
            config.headers["Accept"] = "application/json";
            config.headers["Content-Type"] = "application/json";
        }
        return config;
    }, (error) => {
        return error;
    });
};

export default requestInterceptor;