require('dotenv').config()
export const API_URL = Object.freeze({
    BASE_URL: process.env.REACT_APP_API_URL,
    BASE_HREF: "/api/v1",

    CRUD_EXAMPLE: {
        ADD: '/add',
        DELETE: '/delete',
        UPDATE: '/update',
        TEST_OK: '/ok',
        TEST_401: 'test401'
    }
});