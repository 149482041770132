import HttpClient from './core.service'
const { get, post, del, put } = HttpClient

export const login = (payload: any) => post('/auth/login', payload)
export const forgotPassword = (payload: any) => post('/auth/forgot_password', payload)
export const resetPassword = (payload: any) => post(`/auth/reset-password`, payload)
// export const getListExample = (payload: any) => get('/api/v1/example', payload);
// export const deleteExample = (id: any) => del(`/api/v1/example/${id}`);
// export const createExample = (payload: any) => post('/api/v1/example', payload);
// export const getDetailExample = (id: any) => get(`/api/v1/example/${id}`);
// export const updateExample = (payload: any, id: any) =>
//   put(`/api/v1/example/${id}`, payload);
