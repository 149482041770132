import React, {useEffect, useState} from 'react'
import styles from './style.module.scss'
import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {BackwardOutlined, EyeOutlined} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import SearchCustom from 'app/components/Search/SearchCustom'
import {useGetUserDetail} from "app/hooks/useUser";
import {useGetNotificationDetail} from "app/hooks/userDog";
import moment from "moment";
import { Helmet } from 'react-helmet';
interface DataType {
  key: React.Key
  namePet: string
  birthDayPet: string
  codePet: string
}


const NotifyDetail = () => {
  const { t } = useTranslation('')
  const { id } = useParams()
  const [user, setNotification]:any = useState<{ [x: string]: any }>({})
  const { data: notificationDetail, isSuccess } = useGetNotificationDetail(id)
  const navigate = useNavigate();
  const navigateToUser = () => {
    navigate('/notify');
  };
  
  useEffect(() => {
    if (notificationDetail) {
      setNotification(notificationDetail.data.data)
    }
  }, [notificationDetail])
  const columns: ColumnsType<DataType> = [
    {
      title: t('userManager.userDetail.label.namePet'),
      dataIndex: 'namePet',
      key: '1',
      width: 150,
    },
    {
      title: t('userManager.userDetail.label.birthDayPet'),
      dataIndex: 'birthDayPet',
      key: '2',
      width: 150,
    },
    {
      title: t('userManager.userDetail.label.codePet'),
      dataIndex: 'codePet',
      key: '3',
      width: 150,
    },
  ]

  return (
    <div>
      <Helmet>
        <title>迷子犬の通報</title>
      </Helmet>
      <header>
        <h4 style={{padding: "20px 0"}}>{t('notifyManager.notifyDetail.title')}</h4>
      </header>
      <main>
         <div className={'wrapContent'} style={{ overflowY: "scroll", paddingBottom: "300px", scrollbarWidth: "none", msOverflowStyle: "none" }}>
           <div className={styles.wrapContentItem}>
             <span className={styles.itemTitle}>{t('notifyManager.notifyDetail.petInfo.title')}</span>
             <div className={styles.contentDetail}>
               <ul>
                 <li className={styles.boxDogItem}>
                   <span className={styles.itemLeft} >{t('notifyManager.notifyDetail.petInfo.namePet')}: </span>
                   <span className={styles.itemRight} style={{ paddingLeft: 20 }}>{user.dog && user.dog.dog_name }</span>
                 </li>
                 <li className={styles.boxDogItem}>
                   <span className={styles.itemLeft} >{t('notifyManager.notifyDetail.petInfo.bithDayPet')}: </span>
                   <span className={styles.itemRight} style={{ paddingLeft: 20 }}>{ moment(user.dog && user.dog.birth_dt).format('YYYY-MM-DD') }</span>
                 </li>
                 <li className={styles.boxDogItem} >
                   <span className={styles.itemLeft}>{t('notifyManager.notifyDetail.petInfo.petBreed')}: </span>
                   <span className={styles.itemRight} style={{ paddingLeft: 20 }}>{user.dog && user.dog.dog_breed.dog_breed_name_ja}</span>
                 </li>
                 <li className={styles.boxDogItem} >
                   <span className={styles.itemLeft}>{t('notifyManager.notifyDetail.petInfo.petGender')}: </span>
                   <span className={styles.itemRight} style={{ paddingLeft: 20 }}>{user.dog && user.dog.dog_gender == "M" ? 'オス' : 'メス' }</span>
                 </li>
                 <li className={styles.boxDogItem} >
                   <span className={styles.itemLeft}>{t('notifyManager.notifyDetail.petInfo.petCode')}: </span>
                   <span className={styles.itemRight} style={{ paddingLeft: 20 }}>{user.dog && user.dog.dog_number }</span>
                 </li>
               </ul>
             </div>
           </div>
           <div className={styles.wrapContentItem}>
             <span className={styles.itemTitle}>{t('notifyManager.notifyDetail.infoUser.title')}</span>
             <div className={styles.contentDetail}>
               <ul className={styles.boxLeft}>
                 <li className={styles.boxDogItem}>
                   <span className={styles.itemLeft}>{t('notifyManager.notifyDetail.infoUser.userName')}:  </span>
                   <span className={styles.itemRight} style={{ paddingLeft: 20 }}>{user.member && user.member.nickname }</span>
                 </li>
                 <li className={styles.boxDogItem}>
                   <span className={styles.itemLeft}>{t('notifyManager.notifyDetail.infoUser.email')}: </span>
                   <span className={styles.itemRight} style={{ paddingLeft: 20 }}>{user.member && user.member.email }</span>
                 </li>
                 <li className={styles.boxDogItem}>
                   <span className={styles.itemLeft}>{t('notifyManager.notifyDetail.infoUser.lastLogin')}: </span>
                   <span className={styles.itemRight} style={{ paddingLeft: 20 }}>{ moment(user.member && user.member.last_login).format('YYYY-MM-DD HH:mm:ss') }</span>
                 </li>
               </ul>
             </div>
           </div>
           <div className={styles.wrapContentItem}>
          <span className={styles.itemTitle}>
            {t('notifyManager.notifyDetail.infoGuardian.title')}
          </span>
             <div className={styles.contentDetail}>
               <ul className={styles.boxLeft}>
                 <li className={styles.boxDogItem}>
                   <span className={styles.itemLeft}>{t('notifyManager.notifyDetail.infoGuardian.userName')}: </span>
                   <span className={styles.itemRight} style={{ paddingLeft: 20 }}>{user.member && user.member.nickname }</span>
                 </li>
                 <li className={styles.boxDogItem}>
                   <span className={styles.itemLeft}>{t('notifyManager.notifyDetail.infoGuardian.email')}: </span>
                   <span className={styles.itemRight} style={{ paddingLeft: 20 }}>{user.member && user.member.email }</span>
                 </li>
                 <li className={styles.boxDogItem}>
                   <span className={styles.itemLeft}>{t('notifyManager.notifyDetail.infoGuardian.timeReport')}: </span>
                   <span className={styles.itemRight} style={{ paddingLeft: 20 }}>{ moment(user.member && user.member.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
                 </li>
                 <li className={styles.boxDogItem}>
                   <span className={styles.itemLeft}>{t('notifyManager.notifyDetail.infoGuardian.lastLogin')}: </span>
                   <span className={styles.itemRight} style={{ paddingLeft: 20 }}>{ moment(user.member && user.member.last_login).format('YYYY-MM-DD HH:mm:ss') }</span>
                 </li>
                 <li className={styles.boxDogItem}>
                   <span className={styles.itemLeft}>{t('notifyManager.notifyDetail.infoGuardian.ipLogin')}: </span>
                   <span className={styles.itemRight} style={{ paddingLeft: 20 }}>{user.member && user.member.ip }</span>
                 </li>
               </ul>
             </div>
           </div>
         </div>
      </main>
      <div className="footerDetail">
        <button className="btnBack" onClick={navigateToUser}>
          <BackwardOutlined className="iconBack"/>
          <p className="textBack">戻る</p>
        </button>
      </div>
    </div>
  )
}

export default NotifyDetail
