import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import { QueryClient, QueryClientProvider, QueryCache } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import Router from './router/routers'
import reportWebVitals from './reportWebVitals'
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import './i18n';
import 'antd/dist/reset.css';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from 'react-router-dom'

const AppLayout = React.lazy(() => import('app/layouts/App'))

const queryCache = new QueryCache({
  onError: (error: any) => error,
})
const queryClient = new QueryClient({ queryCache })

const originalSetItem = localStorage.setItem
localStorage.setItem = function () {
  document.createEvent('Event').initEvent('itemInserted', true, true)
  originalSetItem.apply(this, arguments as any)
}

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <QueryClientProvider client={queryClient} contextSharing={true}>
        <BrowserRouter>
          <AppLayout renderRouter={Router} />
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </I18nextProvider>
  </React.StrictMode>
)

reportWebVitals()
