import { StatusCode } from "app/types/enums";
import { AxiosInstance } from "axios";

const responseInterceptor = (axiosInstance: AxiosInstance) => {
    axiosInstance.interceptors.response.use((response) => {
        if (response.data) {
            return {
                status: response.status,
                data: response.data
            }
        }
        return response;

    }, (error) => {
        if (error.response && error.response.status === StatusCode.UNAUTHORIZED) {
            //Unauthorized
            //redirect to Login
        } 
        if(error.response && error.response.status === StatusCode.NOT_FOUND) {
            // Not found
            // Alert
        }
        return error.response || error;
    });
};

export default responseInterceptor;