import React, {useEffect, useState} from 'react'
import { Button, Form, Image, Input } from 'antd'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { useForgotPassword } from 'app/hooks/useAuth'
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation('')
  const { mutate, data , isLoading, isSuccess, error } = useForgotPassword()
  const [isDisabled, setDisabled] = useState(false)
  const [email, setEmail] = useState(null)
  const onFinish = (values: any) => {
    setEmail(values.email)
    setDisabled(true)
    mutate(values)
  }

  useEffect(()=> {
    if(isSuccess) {
      if (data.data.data === true) {
        toast(`${email} にメールを送信しました。`)
      } else {
        toast('そのメールアドレスは登録されていません。')
      }
      setDisabled(false)
    }
  },[isSuccess])

  useEffect(()=> {
    if(error) {
      toast('そのメールアドレスは登録されていません。')
      setDisabled(false)
    }
  },[error])

  return (
    <div className="wrapCenter">
      <Helmet>
        <title>함께걷개</title>
      </Helmet>
      <div className={styles.wrapLogin}>
        <header>
          <Image
            src={require('../../../assets/images/logo.png')}
            width={150}
            className={styles.logo}
            preview={false}
          />
        </header>
        <Form
          name="Forgot-Password"
          className={styles.loginForm}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            label="メールアドレス"
            name="email"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                  if (value && value.length > 255) {
                    return Promise.reject(new Error('255文字以内で入力してください。'));
                  } else if (!value || value === '') {
                    return Promise.reject(new Error('入力してください。'));
                  } else if (!pattern.test(value)) {
                    return Promise.reject(new Error('メールアドレスを正しく入力してください。'));
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Input placeholder={t('forgotPassword.placeholder.email')} />
          </Form.Item>

          <Form.Item>
            <Button disabled={isDisabled} type="primary" htmlType="submit" className={styles.loginBtn}>
              {t('forgotPassword.button.title')}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <ToastContainer/>
    </div>
  )
}

export default ForgotPassword
