import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { Link } from 'react-router-dom'
import { EyeOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import SearchCustom from 'app/components/Search/SearchCustom'
import { useGetUserList } from 'app/hooks/useUser'
import CustomPagination from 'app/components/Pagination'
import { Helmet } from 'react-helmet';
interface DataType {
  key: React.Key
  id: React.Key
  email: string
  userName: string
  createAt: string
  lastLogin: string
  ipLogin: string
}
interface dataSearch {
  startTime: string,
  endTime: string,
  nickname: string
}

const UserManger = () => {
  const moment = require('moment-timezone');
  const { t } = useTranslation('')
  const [listUser, setListUser] = useState([])
  const [payload, setPayload] = useState({
    nickname: undefined,
    page: 1
  })
  const [currentPage, setCurrentPage] = useState(1)
  const { data: list, isLoading, isError, isSuccess } = useGetUserList(payload)
  const [pageSize, setPageSize] = useState(10)
  const [totalItems, setTotalItems] = useState(0)
  
  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    setPayload({...payload, page: page})
  }
  
  const handleSearchUser = (value:any) => {
    // Call API search user
    setPayload(value)
  }

  useEffect(() => {
    if (list) {
      setListUser(list.data.data)
      setPageSize(list.data.meta.take)
      setTotalItems(list.data.meta.item_count)
    }
  }, [list])

  const columns: ColumnsType<DataType> = [
    {
      title: t('userManager.label.email'),
      dataIndex: 'email',
      key: '1',
      width: 150,
    },
    {
      title: t('userManager.label.userName'),
      dataIndex: 'nickname',
      key: '2',
      width: 150,
    },
    {
      title: t('userManager.label.createAt'),
      dataIndex: 'created_at',
      key: '3',
      width: 150,
      align: "center",
      render: (value) => {
        return <span>{moment(value).format('YYYY-MM-DD')}</span>
      },
    },
    {
      title: t('userManager.label.lastLogin'),
      dataIndex: 'last_login',
      key: '4',
      width: 150,
      align: "center",
      render: (value) => {
        return <span>{moment(value).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm:ss')}</span>
      },
    },
    {
      title: t('userManager.label.ipLogin'),
      dataIndex: 'ip',
      key: '5',
      width: 150,
      align: "center"
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 50,
      align: 'center',
      render: (value) => <Link to={`/user/${value.id}`}>{<EyeOutlined />}</Link>,
    },
  ]
  
  let locale = {
    emptyText: 'データがありません。',
  };
  return (
    <div className={styles.wrapContainer}>
      <Helmet>
        <title>ユーザー一覧</title>
      </Helmet>
      <header>
        <h4>{t('userManager.title')}</h4>
        <SearchCustom
          isSearchText
          handleSearch={handleSearchUser}
        />
      </header>
      <main>
        <Table
          locale={locale}
          columns={columns}
          dataSource={listUser}
          pagination={false}
        />
        <CustomPagination
          totalItems={totalItems}
          currentPage={currentPage}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </main>
    </div>
  )
}

export default UserManger
