import axios from 'axios';
import { STORAGE } from 'app/constants/common';
import { load } from 'react-cookies';
import { API_URL } from 'app/constants';
import responseInterceptor from './response.interceptor';
import requestInterceptor from './request.interceptor';

const getUrlPrefix = () => API_URL.BASE_URL + API_URL.BASE_HREF;

const instance = axios.create({
    baseURL: API_URL.BASE_URL + API_URL.BASE_HREF
});

responseInterceptor(instance);
requestInterceptor(instance);

const token: string = load(STORAGE.ACCESS_TOKEN) || ('' as string);
if (token) instance.defaults.headers.common.Authorization = `Bearer ${token}`;


const get = async (url: string, params: any = {}) => {
    try {
        const { cancelToken, ...conf } = params;
        const config = { cancelToken: cancelToken, params: conf };
        const response = await instance.get(getUrlPrefix() + url, config);
        return response;
    } catch (error) {
        return _errorHandler(error);
    }
};


const put = async (url: string, data: any = {}): Promise<any> => {
    try {
        if (data.toLocaleString() === '[object FormData]') {
            return await instance.put(getUrlPrefix() + url, data)
        } else {
            return await instance.put(getUrlPrefix() + url, data)
        }
    } catch (error) {
        _errorHandler(error)
    }
}

const post = async (url: string, payload: any = {}): Promise<any> => {
    try {
        const response = await instance.post(getUrlPrefix() + url, payload)
        return response;
    } catch (error) {
        _errorHandler(error)
    }
}

const del = async (url: string, data: any = {}): Promise<any> => {
    try {
        const response = await instance.delete(getUrlPrefix() + url, { data })
        return response;
    } catch (error) {
        _errorHandler(error)
    }
}

const _errorHandler = (err: any) => {
    if (axios.isAxiosError(err)) {
        return err.message
    } else {
        if (err.response && err.response.status === 401) {
            // todo
        }
        throw err
    }
}

const HttpClient = {
    get, post, del, put
};

export default HttpClient;
