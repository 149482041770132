import React, { FC } from 'react'
import {
  RouteType,
  ProtectedRouteProps,
  WhitelistRouteProps,
} from 'app/types/interfaces/router/Common'
import { Routes, Route, Navigate } from 'react-router-dom'
import { load } from 'react-cookies'
import { STORAGE } from '../constants/common'
import { appRouter } from './appRouter'

const Home = React.lazy(() => import('app/pages/Home'))
const NotFound = React.lazy(() => import('app/pages/NotFound'))

const whiteList: string[] = ['/login', '/forgot-password', '/reset-password', '/register']
const FULL_PERMISSION = '*'

const ProtectedRoute = ({ isAuthenticated, authenticationPath, outlet }: ProtectedRouteProps) =>
  isAuthenticated ? outlet :  <Navigate to={{ pathname: authenticationPath }} />
const WhiteListedRoute = ({ isWhiteList, authenticationPath, outlet }: WhitelistRouteProps) =>
  !isWhiteList ? outlet : <Navigate to={{ pathname: authenticationPath }} />

const AppRoutes: FC<any> = () => {
  const location = window.location.pathname
  const accessToken: string = load(STORAGE.ACCESS_TOKEN) || ('' as string)

  const defaultWhitelistRouteProps: Omit<WhitelistRouteProps, 'outlet'> = {
    isWhiteList: !!(accessToken && whiteList.indexOf(location) >= 0),
    authenticationPath: '/',
  }
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
    isAuthenticated: !!accessToken,
    authenticationPath: '/login',
  }
  return (
    <>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/">
          <Route
            index={true}
            element={ <Navigate to="/user" />}
          />
          {appRouter.map((route: RouteType, index: number) => {
            const { meta, component: Component, path } = route
            if (!checkPermission(meta.role)) return
            return meta.isPrivate ?  (
              <Route
                key={`${index}-router`}
                path={path}
                element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<Component />} />}
              />
            ) : (
              <Route
                key={`${index}-router`}
                path={path}
                element={
                  <WhiteListedRoute {...defaultWhitelistRouteProps} outlet={<Component />} />
                }
              />
            )
          })}
        </Route>
      </Routes>
    </>
  )
}

const checkPermission = (permission: string | string[]) => {
  const infor = 'role'
  if (permission === FULL_PERMISSION) return true
  return permission.indexOf(infor) >= 0
}

export default AppRoutes
