import React from 'react'
import { Pagination } from 'antd'
import styles from './styles.module.scss'
interface propsData {
  totalItems: number
  currentPage: number
  pageSize: number
  onPageChange: Function
}
const CustomPagination = ({ totalItems, currentPage, pageSize, onPageChange }: propsData) => {
  const handlePageChange = (page: number, pageSize: number) => {
    onPageChange(page)
  }

  return (
    <div className={styles.wrapPanigation}>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={totalItems}
        onChange={handlePageChange}
        showSizeChanger={false}
      />
    </div>
  )
}

export default CustomPagination
